/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';

import AppInitializer from './AppInitializer';
import AppInsights from './tracking/AppInsights';
import Router from './pages/Routes'; // should not be in pages
import Shop from './Shop';

import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faGlobe,
  faGlobeAmericas,
  faGlobeEurope,
  faPlay,
  faAngleUp,
  faAngleRight,
  faAngleDown,
  faAngleLeft,
  faAngleDoubleUp,
  faAngleDoubleRight,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faCheckCircle,
  faTimesCircle,
  faFire,
  faCog,
  faWind,
  faDownload,
  faBatteryEmpty,
  faBatteryHalf,
  faBatteryFull,
  faBars,
} from '@fortawesome/free-solid-svg-icons';

import NotificationsPopup from './layout/NotificationsPopup/NotificationsPopup';
import ErrorBoundary from './tracking/ErrorBoundary';

library.add(
  faGlobe,
  faGlobeAmericas,
  faGlobeEurope,
  faPlay,
  faAngleUp,
  faAngleRight,
  faAngleDown,
  faAngleLeft,
  faAngleDoubleUp,
  faAngleDoubleRight,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faCheckCircle,
  faTimesCircle,
  faFire,
  faCog,
  faWind,
  faDownload,

  faBatteryEmpty,
  faBatteryHalf,
  faBatteryFull,

  faBars,
);

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

const App = () => (
  <>
    <AppInsights />
    <ErrorBoundary>
      <NotificationsPopup />
      <AppInitializer>
        <Shop />
        <Router />
      </AppInitializer>
    </ErrorBoundary>
  </>
);

App.propTypes = {
  children: PropTypes.element,
};

export default hot(module)(App);
