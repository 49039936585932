// @ts-check
import { MY_UPLINK_CLIENT_ID, CONFIG_FORM, API } from './index';
import { getCodeVerifierAndRemoveFromLocalStorage, getAzureB2CPolicyName } from '../localStorage/authentication';
import { randomCode, generateAzureB2CCodeVerifierAndCodeChallenge } from '../utils/azureB2C';
import { getCurrentCountryCode } from './countries';

export const AZURE_B2C_TENANT_ID = process.env.AZURE_B2C_TENANT_ID || '';
export const AZURE_B2C_CLIENT_ID = process.env.AZURE_B2C_CLIENT_ID || '';
export const AZURE_B2C_POLICY_LOGIN = 'B2C_1A_LOGIN_FLOW_EMAIL_CONSUMERS';
export const AZURE_B2C_POLICY_PASSWORD_CHANGE = 'B2C_1A_PASSWORDCHANGE';

export const AuthOptions = {
  SIGN_IN: 'signin',
  SIGN_UP: 'signup',
};

const azureB2CBaseUrl = `https://${AZURE_B2C_TENANT_ID}.b2clogin.com/${AZURE_B2C_TENANT_ID}.onmicrosoft.com`;
const azureB2CScope = `https://${AZURE_B2C_TENANT_ID}.onmicrosoft.com/api/READSYSTEM https://${AZURE_B2C_TENANT_ID}.onmicrosoft.com/api/WRITESYSTEM`;

/**
 * Generates and returns the Azure B2C login link with specified options.
 *
 * @async
 * @param {{redirectTo?: string, language?: string, authOption?: string}} options - The options for generating the login link.
 * @returns {Promise<string>} The Azure B2C login link.
 */
export const getAzureB2CLoginLink = async ({ redirectTo, language, authOption } = {}) => {
  try {
    const { codeChallenge } = await generateAzureB2CCodeVerifierAndCodeChallenge();
    const countryCode = getCurrentCountryCode();

    const baseUrl = `${azureB2CBaseUrl}/${AZURE_B2C_POLICY_LOGIN}/oauth2/v2.0/authorize`;
    const params = new URLSearchParams({
      client_id: AZURE_B2C_CLIENT_ID,
      response_type: 'code',
      redirect_uri: `${window.location.origin}/login`,
      response_mode: 'query',
      scope: azureB2CScope,
      state: redirectTo || '',
      lang: language || '',
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
      clientId: MY_UPLINK_CLIENT_ID,
      countryCode: countryCode || '',
      authOption: authOption || AuthOptions.SIGN_IN,
    });

    return `${baseUrl}?${params.toString()}`;
  } catch (e) {
    console.error('Failed to generate Azure B2C login link:', e);
    throw new Error('Error generating login link');
  }
};

/**
 * Issues Azure B2C tokens using an authorization code.
 *
 * @param {string} code - The authorization code obtained after user authentication.
 * @param {string} policyId - The policy ID to be used in the token request.
 * @returns {Promise<AccessToken>} A Promise that resolves when the tokens are issued successfully.
 */
export const issueAzureB2CTokens = (code, policyId) => {
  try {
    const codeVerifier = getCodeVerifierAndRemoveFromLocalStorage();

    const baseUrl = `${azureB2CBaseUrl}/${policyId}/oauth2/v2.0/token`;
    const body = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: AZURE_B2C_CLIENT_ID,
      scope: policyId === AZURE_B2C_POLICY_PASSWORD_CHANGE ? `${azureB2CScope} offline_access` : azureB2CScope,
      code: code,
      redirect_uri: `${window.location.origin}/login`,
      code_verifier: codeVerifier,
    });
    return API.post(baseUrl, body, CONFIG_FORM);
  } catch (e) {
    console.error('Failed to issue Azure B2C tokens:', e);
    throw new Error('Error issuing tokens');
  }
};

/**
 * Reissues Azure B2C tokens using a refresh token.
 *
 * @param {string} refreshToken - The refresh token used to obtain new tokens.
 * @returns {Promise<AccessToken>} A Promise that resolves when the tokens are reissued successfully.
 */
export const reissueAzureB2CTokens = refreshToken => {
  try {
    const policyId = getAzureB2CPolicyName();
    const baseUrl = `${azureB2CBaseUrl}/${policyId}/oauth2/v2.0/token`;
    const body = new URLSearchParams({
      grant_type: 'refresh_token',
      client_id: AZURE_B2C_CLIENT_ID,
      refresh_token: refreshToken,
    });
    return API.post(baseUrl, body, CONFIG_FORM);
  } catch (e) {
    console.error('Failed to reissue Azure B2C tokens:', e);
    throw new Error('Error reissuing tokens');
  }
};

/**
 *  Generates and returns the Azure B2C logout link.
 *
 * @returns {string} The Azure B2C logout link.
 */
export const getAzureB2CLogoutLink = () => {
  try {
    const baseUrl = `${azureB2CBaseUrl}/oauth2/v2.0/logout`;
    const policyId = getAzureB2CPolicyName();

    const params = new URLSearchParams({
      p: policyId,
      post_logout_redirect_uri: `${window.location.origin}/login`,
    });

    return `${baseUrl}?${params.toString()}`;
  } catch (e) {
    console.error('Failed to generate Azure B2C logout link:', e);
    throw new Error('Error generating logout link');
  }
};

/**
 * Generates and returns the Azure B2C Change Password link.
 *
 * @async
 * @param {string} returnUri - The URL to redirect to after the password has been changed.
 * @param {string} language - The language selected by de user.
 * @returns {Promise<string>} The Azure B2C Change Password link.
 */
export const getAzureB2CChangePasswordLink = async (returnUri, language) => {
  try {
    const { codeChallenge } = await generateAzureB2CCodeVerifierAndCodeChallenge();
    const baseUrl = `${azureB2CBaseUrl}/${AZURE_B2C_POLICY_PASSWORD_CHANGE}/oauth2/v2.0/authorize`;

    const params = new URLSearchParams({
      client_id: AZURE_B2C_CLIENT_ID,
      response_type: 'code',
      redirect_uri: returnUri,
      scope: `${azureB2CScope} offline_access`,
      state: randomCode(8),
      lang: language || '',
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
      clientId: MY_UPLINK_CLIENT_ID,
    });

    return `${baseUrl}?${params.toString()}`;
  } catch (e) {
    console.error('Failed to generate Azure B2C Change Password link:', e);
    throw new Error('Error generating change password link');
  }
};

/**
 * @typedef {Object} AccessToken
 * @property {string} access_token
 * @property {number} expires_in
 * @property {number} expires_on
 * @property {number} not_before
 * @property {string} profile_info
 * @property {string} refresh_token
 * @property {number} refresh_token_expires_in
 * @property {string} resource
 * @property {string} scope
 * @property {string} token_type
 */
