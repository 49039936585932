/* eslint-disable import/default */
import 'react-app-polyfill/ie9';

import React from 'react';
import ReactDOM, { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Helmet } from 'react-helmet';
import store from './store';
import { history } from './store/configureStore';
import Root from './Root';
import {
  // AXE_DEBUG,
  ENV,
} from './api';

import './styles/styles.scss'; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
require('./assets/img/favicon.ico'); // Tell webpack to load favicon.ico

import { registerAuthHeaderInterceptor } from './api/requestInterceptor';

registerAuthHeaderInterceptor(store);

const isDev = !ENV || ENV === 'localhost';

// Accessibility error logging to browser console
if (isDev /* && AXE_DEBUG*/) {
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

render(
  <AppContainer>
    <>
      <Helmet>
        <script type="text/javascript" src={`/assets/js/clarity/clarity.${ENV}.js`}></script>
      </Helmet>
      <Root store={store} history={history} />
    </>
  </AppContainer>,
  document.getElementById('app'),
);

if (module.hot) {
  module.hot.accept('./Root', () => {
    const NewRoot = require('./Root').default;
    render(
      <AppContainer>
        <>
          <Helmet>
            <script type="text/javascript" src={`/assets/js/clarity/clarity.${ENV}.js`}></script>
          </Helmet>
          <NewRoot store={store} history={history} />
        </>
      </AppContainer>,
      document.getElementById('app'),
    );
  });
}
