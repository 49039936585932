import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'jpi-cloud-web-ui-components';

import { getUserInfo, getUserSystems } from '../../AppInitializer/actions';
import { removeSystemTiles, unsetSystemAsPrepopulated } from '../../tiles/TileManager/actions';
import {
  changePassword,
  deleteAccount,
  resetDeleteError,
  validateUserCredentials,
  issueAzureB2CTokensAfterPasswordChangeAction,
} from './actions';
import ProfileTab from './components/ProfileTab/ProfileTab';
import AppearanceTab from './components/AppearanceTab/AppearanceTab';
import DeleteAccountTab from './components/DeleteAccountTab/DeleteAccountTab';
import ChangePasswordForm from './components/ChangePasswordForm';
import { getAzureB2CChangePasswordLink } from '../../../api/azureB2C';
import ThirdPartyApps from './components/ThirdPartyApps/ThirdPartyApps';
import NotificationSettingsTab from './components/NotificationSettingsTab/NotificationSettingsTab';
import { tabNames } from '../../constants/constants';
import './profilePage.scss';

const ProfilePage = ({
  userInfo,
  profilePage,
  profileTab,
  getUserInfo,
  getUserSystems,
  removeSystemTiles,
  unsetSystemAsPrepopulated,
  changePassword,
  deleteAccount,
  resetDeleteError,
  validateUserCredentials,
  issueAzureB2CTokensAfterPasswordChangeAction,
  history,
  selectedLanguage,
}) => {
  const [state, setState] = useState({
    isLoaded: false,
    isEmailEditShown: false,
    isEmailConfirmationSent: false,
    isAddressEditShown: false,
    isPasswordChanged: false,
    selectedTab: 'profile',
    deletionToken: '',
  });

  const getClassName = tabName =>
    classNames(state.selectedTab === tabName ? 'option-label selected' : 'option-label', isDisabledClassName);

  const tabs = [
    { id: tabNames.PROFILE, labelId: 'label.profile', defaultLabel: 'Profile' },
    { id: tabNames.APPEARANCE, labelId: 'label.appearance', defaultLabel: 'Appearance' },
    { id: tabNames.NOTIFICATION_SETTINGS, labelId: 'label.notification-settings', defaultLabel: 'Notifications' },
    { id: tabNames.PERMISSIONS, labelId: 'label.permissions', defaultLabel: 'Permissions' },
    { id: tabNames.PASSWORD, labelId: 'label.password', defaultLabel: 'Password' },
    { id: tabNames.DELETE, labelId: 'label.delete', defaultLabel: 'Remove Account' },
  ];

  const selectTab = tab => {
    const isDemo = userInfo && userInfo.isDemo;
    if (isDemo && (tab === 'email' || tab === 'password' || tab === 'delete')) {
      return false;
    }
    setState(prevState => ({ ...prevState, selectedTab: tab }));
  };

  const init = async () => {
    handleRedirectionAfterPasswordChange();
    handleDeletionToken();
    await getUserInfo();
    setState(prevState => ({ ...prevState, isLoaded: true }));
  };

  useEffect(() => {
    init();
  }, []);

  const getCurrentTab = () => {
    switch (state.selectedTab) {
      case 'profile':
        return <ProfileTab userInfo={userInfo} requestError={profileTab.profileError} addressId={userInfo.addressId} />;
      case 'appearance':
        return <AppearanceTab />;
      case 'notification-settings':
        return <NotificationSettingsTab />;
      case 'password':
        return (
          <div className="tabContainer">
            <ChangePasswordForm
              requestError={profilePage.passwordError}
              isChanged={state.isPasswordChanged}
              onSubmit={onPasswordSubmit}
              changePasswordWithAzureB2C={changePasswordWithAzureB2C}
            />
          </div>
        );
      case 'permission':
        return <ThirdPartyApps />;
      case 'delete':
        return (
          <DeleteAccountTab
            deleteError={profilePage.deleteError}
            deleteUser={deleteAccount}
            getUserSystems={getUserSystems}
            resetDeleteError={resetDeleteError}
            removeSystemTiles={removeSystemTiles}
            unsetSystemAsPrepopulated={unsetSystemAsPrepopulated}
            userId={userInfo.id}
            userEmail={userInfo.email}
            history={history}
            validatePasswordError={profilePage.validatePasswordError}
            validateUserCredentials={validateUserCredentials}
            deletionToken={state.deletionToken}
          />
        );
      default:
        return null;
    }
  };

  const onPasswordSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    if (await changePassword(userInfo.id, values.oldPassword.trim(), values.newPassword.trim())) {
      setState(prevState => ({ ...prevState, isPasswordChanged: true }));
      resetForm();
      setSubmitting(false);
      setTimeout(() => {
        setState(prevState => ({ ...prevState, isPasswordChanged: false }));
      }, 2000);
    } else {
      setSubmitting(false);
    }
  };

  const changePasswordWithAzureB2C = async () => {
    const returnUri = window.location.origin + '/profile-settings';

    window.location.href = await getAzureB2CChangePasswordLink(returnUri, selectedLanguage);
  };

  const handleRedirectionAfterPasswordChange = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    if (code) {
      issueAzureB2CTokensAfterPasswordChangeAction(code);
    }
  };

  const handleDeletionToken = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');

    if (token) {
      setState(prevState => ({
        ...prevState,
        selectedTab: 'delete',
        deletionToken: token,
      }));
    }
  };

  if (!state.isLoaded) {
    return <Spinner />;
  }

  const isDemo = userInfo && userInfo.isDemo;
  const isDisabledClassName = isDemo ? 'disabled' : '';

  return (
    <div className="page-content">
      <div className="profile-page">
        <div className="tabordion">
          <section className="profile-section" id="profile-section">
            {tabs.map(tab => (
              <div key={tab.id} onClick={() => selectTab(tab.id)} className={getClassName(tab.id)}>
                <FormattedMessage id={tab.labelId} defaultMessage={tab.defaultLabel} />
              </div>
            ))}
          </section>
          <div className="tab-content">{getCurrentTab()}</div>
        </div>
      </div>
    </div>
  );
};

ProfilePage.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  getUserSystems: PropTypes.func.isRequired,
  removeSystemTiles: PropTypes.func.isRequired,
  unsetSystemAsPrepopulated: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  resetDeleteError: PropTypes.func.isRequired,
  history: PropTypes.object,
  profilePage: PropTypes.object,
  userInfo: PropTypes.object,
  validateUserCredentials: PropTypes.func.isRequired,
  profileTab: PropTypes.object,
  issueAzureB2CTokensAfterPasswordChangeAction: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string,
};

const mapStateToProps = ({ app: { userInfo }, profilePage, profileTab, language: { selectedLanguage } }) => ({
  userInfo,
  profilePage,
  profileTab,
  selectedLanguage,
});

const mapDispatchToProps = {
  getUserInfo,
  getUserSystems,
  removeSystemTiles,
  unsetSystemAsPrepopulated,
  changePassword,
  deleteAccount,
  resetDeleteError,
  validateUserCredentials,
  issueAzureB2CTokensAfterPasswordChangeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
